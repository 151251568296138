import { useQuery } from "@hooks";
import theme from "@static/theme";
import React, { useState, useEffect, useRef } from "react";
import FlexColumn from "@components/UI/FlexColumn";

const TitleIcon = (): JSX.Element => {
  const { isSm } = useQuery();

  return (
    <FlexColumn
      id="title-container"
      style={{
        margin: "auto",
        position: "relative",
        zIndex: 0,
        padding: isSm ? "10vmin" : "",
      }}
    >
      {!isSm && (
        <img
          src={`${process.env.PUBLIC_URL}/SVG/TestCricles.svg`}
          style={{
            width: "max(10vw, 50vmin)",
            height: "max(10vw, 50vmin)",
            zIndex: 0,
          }}
        />
      )}
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="490.8px"
        height="93.3px"
        viewBox="0 0 490.8 93.3"
        style={{
          position: "absolute",
          width: "max(10vw, 50vmin)",
          transform: `translate(${-50}%, -50%)`,
          left: "50%",
          top: "50%",
          zIndex: 1,
        }}
      >
        <g>
          <path
            fill="white"
            d="M53.7,17.6c-0.9-0.4-2-0.6-3.2-0.6h-4.6c-1.2,0-2.3,0.2-3.2,0.6c-0.9,0.4-1.4,0.9-1.4,1.5v2.1L32.1,17H13.8L0,23.3v25.4
		l22.9,23.3h4.6l4.6-10.6v14.8H18.3L9.2,61.5H4.6c-1.2,0-2.3,0.2-3.2,0.6S0,63,0,63.6v27.6c0,0.6,0.5,1.1,1.4,1.5
		c0.9,0.4,2,0.6,3.2,0.6h4.6c1.2,0,2.3-0.2,3.2-0.6c0.9-0.4,1.4-0.9,1.4-1.5V89l9.2,4.2h18.3L55,86.9V61.5L32.1,40.3h-4.6l-4.6,10.6
		V36h13.8l13.8,19.1c1.2,0,2.3-0.2,3.2-0.6c0.9-0.4,1.4-0.9,1.4-1.5V19.1C55,18.5,54.6,18,53.7,17.6z"
          />
          <path
            fill="white"
            d="M117.8,17.7c-1-0.5-2-0.7-3.1-0.7H82.6l-18.3,6.4v63.6l18.3,6.4h32.1c1.1,0,2.1-0.2,3.1-0.6c1-0.4,1.5-0.9,1.5-1.5V76.3
		c0-0.5-0.5-1-1.5-1.4c-1-0.5-2-0.7-3.1-0.7H87.2V70l32.1-19.1V19.1C119.3,18.6,118.8,18.1,117.8,17.7z M96.3,46.6L87.2,53V31.8h9.2
		V46.6z"
          />
          <path
            fill="white"
            d="M149.9,17.7c-1-0.5-2-0.7-3.1-0.7H133c-1.2,0-2.3,0.2-3.2,0.7c-0.9,0.5-1.4,0.9-1.4,1.4v17c0,0.6,0.5,1.1,1.4,1.5
		c0.9,0.4,2,0.6,3.2,0.6h1.5l-6.1,8.5v44.5c0,0.6,0.5,1.1,1.4,1.5c0.9,0.4,2,0.6,3.2,0.6h13.8c1.1,0,2.1-0.2,3.1-0.6
		c1-0.4,1.5-0.9,1.5-1.5V76.3c0-0.5-0.5-1-1.5-1.4c-1-0.5-2-0.7-3.1-0.7h-1.5l6.1-8.5V19.1C151.4,18.6,150.9,18.1,149.9,17.7z"
          />
          <path
            fill="white"
            d="M149.9,0.7c-1-0.5-2-0.7-3.1-0.7H133c-1.2,0-2.3,0.2-3.2,0.7c-0.9,0.5-1.4,0.9-1.4,1.4v8.5c0,0.6,0.5,1.1,1.4,1.5
		c0.9,0.4,2,0.6,3.2,0.6h13.8c1.1,0,2.1-0.2,3.1-0.6c1-0.4,1.5-0.9,1.5-1.5V2.1C151.4,1.6,150.9,1.1,149.9,0.7z"
          />
          <path
            fill="white"
            d="M214.2,17.6c-0.9-0.4-2-0.6-3.2-0.6h-4.6c-1.2,0-2.3,0.2-3.2,0.6c-0.9,0.4-1.4,0.9-1.4,1.5v2.1l-9.2-4.2h-18.3l-13.8,6.4
		v25.4l22.9,23.3h4.6l4.6-10.6v14.8h-13.8l-9.2-14.8h-4.6c-1.2,0-2.3,0.2-3.2,0.6s-1.4,0.9-1.4,1.5v27.6c0,0.6,0.5,1.1,1.4,1.5
		c0.9,0.4,2,0.6,3.2,0.6h4.6c1.2,0,2.3-0.2,3.2-0.6c0.9-0.4,1.4-0.9,1.4-1.5V89l9.2,4.2h18.3l13.8-6.4V61.5l-22.9-21.2h-4.6
		l-4.6,10.6V36h13.8L211,55.1c1.2,0,2.3-0.2,3.2-0.6c0.9-0.4,1.4-0.9,1.4-1.5V19.1C215.6,18.5,215.1,18,214.2,17.6z"
          />
          <path
            fill="white"
            d="M273.7,17.7c-1-0.5-2-0.7-3.1-0.7h-32.1l-13.8,6.4v67.8c0,0.6,0.5,1.1,1.4,1.5c0.9,0.4,2,0.6,3.2,0.6h32.1l13.8-8.5V19.1
		C275.2,18.6,274.7,18.1,273.7,17.7z M259.2,76.3H250V61.5l-9.2-10.6v-17h9.2v12.7l9.2,10.6V76.3z"
          />
          <path
            fill="white"
            d="M305.8,2.8c-1-0.5-2-0.7-3.1-0.7H289c-1.2,0-2.3,0.2-3.2,0.7c-0.9,0.5-1.4,0.9-1.4,1.4v14.8c0,0.6,0.5,1.1,1.4,1.5
		c0.9,0.4,2,0.6,3.2,0.6h1.5l-6.1,8.5v61.5c0,0.6,0.5,1.1,1.4,1.5c0.9,0.4,2,0.6,3.2,0.6h13.8c1.1,0,2.1-0.2,3.1-0.6
		c1-0.4,1.5-0.9,1.5-1.5V76.3c0-0.5-0.5-1-1.5-1.4c-1-0.5-2-0.7-3.1-0.7h-0.9l5.5-6.4V4.2C307.3,3.7,306.8,3.3,305.8,2.8z"
          />
          <path
            fill="white"
            d="M365.5,17.7c-1-0.5-2-0.7-3.1-0.7h-32.1l-13.8,6.4v67.8c0,0.6,0.5,1.1,1.4,1.5c0.9,0.4,2,0.6,3.2,0.6h32.1l13.8-8.5V19.1
		C367,18.6,366.5,18.1,365.5,17.7z M350.9,76.3h-9.2V61.5l-9.2-10.6v-17h9.2v12.7l9.2,10.6V76.3z"
          />
          <path
            fill="white"
            d="M397.6,77c-1-0.5-2-0.7-3.1-0.7h-13.8c-1.2,0-2.3,0.2-3.2,0.7c-0.9,0.5-1.4,0.9-1.4,1.4v12.7c0,0.6,0.5,1.1,1.4,1.5
		c0.9,0.4,2,0.6,3.2,0.6h13.8c1.1,0,2.1-0.2,3.1-0.6c1-0.4,1.5-0.9,1.5-1.5V78.4C399.1,78,398.6,77.5,397.6,77z"
          />
          <path
            fill="white"
            d="M429.7,0.7c-1-0.5-2-0.7-3.1-0.7h-13.8c-1.2,0-2.3,0.2-3.2,0.7c-0.9,0.5-1.4,0.9-1.4,1.4v8.5c0,0.6,0.5,1.1,1.4,1.5
		c0.9,0.4,2,0.6,3.2,0.6h13.8c1.1,0,2.1-0.2,3.1-0.6c1-0.4,1.5-0.9,1.5-1.5V2.1C431.2,1.6,430.7,1.1,429.7,0.7z"
          />
          <path
            fill="white"
            d="M429.7,17.7c-1-0.5-2-0.7-3.1-0.7h-13.8c-1.2,0-2.3,0.2-3.2,0.7c-0.9,0.5-1.4,0.9-1.4,1.4v17c0,0.6,0.5,1.1,1.4,1.5
		c0.9,0.4,2,0.6,3.2,0.6h1.5l-6.1,8.5v44.5c0,0.6,0.5,1.1,1.4,1.5c0.9,0.4,2,0.6,3.2,0.6h13.8c1.1,0,2.1-0.2,3.1-0.6
		c1-0.4,1.5-0.9,1.5-1.5V76.3c0-0.5-0.5-1-1.5-1.4c-1-0.5-2-0.7-3.1-0.7h-1.5l6.1-8.5V19.1C431.2,18.6,430.7,18.1,429.7,17.7z"
          />
          <path
            fill="white"
            d="M489.3,17.7c-1-0.5-2-0.7-3.1-0.7h-32.1l-13.8,6.4v67.8c0,0.6,0.5,1.1,1.4,1.5c0.9,0.4,2,0.6,3.2,0.6h32.1l13.8-8.5V19.1
		C490.8,18.6,490.3,18.1,489.3,17.7z M474.8,76.3h-9.2V61.5l-9.2-10.6v-17h9.2v12.7l9.2,10.6V76.3z"
          />
        </g>
      </svg>
    </FlexColumn>
  );
};

export default TitleIcon;

{
  /* <path
            fill={theme.secondary}
            d="M47.5,0.5H35.8c-0.7,0-1.3,0.5-1.8,1.4c-0.5,1-0.8,2-0.8,3.2v2.3l-3.9-6.9H13.6L0.5,16.6v36.7l19.6,11.5h10.5
          l2.6-6.9v13.8H21.4L7,62.4H3.1c-0.7,0-1.3,0.5-1.8,1.4c-0.5,1-0.8,2-0.8,3.2v27.5c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4
          h13.1c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2v-2.3l3.9,6.9h14.4l13.1-22.9V46.4L30.6,32.6H20.1l-2.6,9.2V28h13.1l13.1,9.2h3.9
          c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2V5.1c0-1.1-0.3-2.2-0.8-3.2C48.8,1,48.2,0.5,47.5,0.5z"
          />
          <path
            fill={theme.secondary}
            d="M93.3,0.5H72.4l-3.9,4.2c-0.1-1-0.3-1.9-0.8-2.7c-0.5-1-1.1-1.4-1.8-1.4H55.4c-0.7,0-1.3,0.5-1.8,1.4
          c-0.5,1-0.8,2-0.8,3.2v20.6c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4H57l-4.3,6.9v34.4l12.4,27.5h3.3h24.8
          c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2V73.9c0-1.1-0.3-2.2-0.8-3.2c-0.5-1-1.1-1.4-1.8-1.4H68.4V45.9L75,62.4h17
          c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2V39.5c0-1.1-0.3-2.2-0.8-3.2c-0.5-1-1.1-1.4-1.8-1.4H72.4l-2.2-4.6h23.1
          c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2V5.1c0-1.1-0.3-2.2-0.8-3.2C94.5,1,93.9,0.5,93.3,0.5z"
          />
          <path
            fill={theme.secondary}
            d="M122,0.5h-20.9c-0.7,0-1.3,0.5-1.8,1.4c-0.5,1-0.8,2-0.8,3.2V28c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4
          h0.2l-2.8,11.5v50.5c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4H122c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2V78.5
          c0-1.1-0.3-2.2-0.8-3.2c-0.5-1-1.1-1.4-1.8-1.4h-1.3l3.9-11.5V5.1c0-1.1-0.3-2.2-0.8-3.2C123.3,1,122.7,0.5,122,0.5z"
          />
          <path
            fill={theme.secondary}
            d="M174.3,0.5h-11.8c-0.7,0-1.3,0.5-1.8,1.4c-0.5,1-0.8,2-0.8,3.2v2.3L156,0.5h-15.7l-13.1,16.1v36.7l19.6,11.5
          h10.5l2.6-6.9v13.8h-11.8l-14.4-9.2h-3.9c-0.7,0-1.3,0.5-1.8,1.4c-0.5,1-0.8,2-0.8,3.2v27.5c0,1.1,0.3,2.2,0.8,3.2
          c0.5,1,1.1,1.4,1.8,1.4h13.1c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2v-2.3l3.9,6.9h14.4l13.1-22.9V46.4l-19.6-13.8h-10.5
          l-2.6,9.2V28h13.1l13.1,9.2h3.9c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2V5.1c0-1.1-0.3-2.2-0.8-3.2C175.5,1,174.9,0.5,174.3,0.5
          z"
          />
          <path
            fill={theme.secondary}
            d="M221.3,0.5H190l-10.5,18.4v75.7c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4h31.4l10.5-18.4V5.1
          c0-1.1-0.3-2.2-0.8-3.2C222.6,1,222,0.5,221.3,0.5z M209.6,69.3H203l-1.3-9.2h-7.8V30.3h6.5l1.3,9.2h7.8V69.3z"
          />
          <path
            fill={theme.secondary}
            d="M264.5,46.4H254l-10.5,18.4V46.4l5.3-9.3c0.4-0.8,0.8-1.6,0.9-2.4c0.2-0.8,0.3-1.9,0.3-3.1V5.1
          c0-1.1-0.3-2.2-0.8-3.2c-0.5-1-1.1-1.4-1.8-1.4h-18.3c-0.7,0-1.3,0.5-1.8,1.4c-0.5,1-0.8,2-0.8,3.2V28c0,1.1,0.3,2.2,0.8,3.2
          c0.5,1,1.1,1.4,1.8,1.4h0.2l-2.8,11.5v50.5c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4h18.3l5.2-6.9v2.3c0,1.1,0.3,2.2,0.8,3.2
          c0.5,1,1.1,1.4,1.8,1.4h9.1c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2V51c0-1.1-0.3-2.2-0.8-3.2C265.7,46.9,265.1,46.4,264.5,46.4
          z"
          />
          <path
            fill={theme.secondary}
            d="M311.5,0.5h-31.4l-10.5,18.4v75.7c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4h31.4l10.5-18.4V5.1
          c0-1.1-0.3-2.2-0.8-3.2C312.7,1,312.1,0.5,311.5,0.5z M299.7,69.3h-6.5l-1.3-9.2h-7.8V30.3h6.5l1.3,9.2h7.8V69.3z"
          />
          <path
            fill={theme.secondary}
            d="M335,69.3h-14.4c-0.7,0-1.3,0.5-1.8,1.4c-0.5,1-0.8,2-0.8,3.2v20.6c0,1.1,0.3,2.2,0.8,3.2
          c0.5,1,1.1,1.4,1.8,1.4H335c0.7,0,1.3-0.5,1.8-1.4c0.5-1,0.8-2,0.8-3.2V73.9c0-1.1-0.3-2.2-0.8-3.2C336.3,69.8,335.7,69.3,335,69.3
          z"
          />
          <path
            fill={theme.secondary}
            d="M365.1,0.5h-20.9c-0.7,0-1.3,0.5-1.8,1.4c-0.5,1-0.8,2-0.8,3.2V28c0,1.1,0.3,2.2,0.8,3.2
          c0.5,1,1.1,1.4,1.8,1.4h0.2l-2.8,11.5v50.5c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4h20.9c0.7,0,1.3-0.5,1.8-1.4
          c0.5-1,0.8-2,0.8-3.2V78.5c0-1.1-0.3-2.2-0.8-3.2c-0.5-1-1.1-1.4-1.8-1.4h-1.3l3.9-11.5V5.1c0-1.1-0.3-2.2-0.8-3.2
          C366.3,1,365.7,0.5,365.1,0.5z"
          />
          <path
            fill={theme.secondary}
            d="M413.9,1.9c-0.5-1-1.1-1.4-1.8-1.4h-31.4l-10.5,18.4v75.7c0,1.1,0.3,2.2,0.8,3.2c0.5,1,1.1,1.4,1.8,1.4h31.4
          l10.5-18.4V5.1C414.7,3.9,414.5,2.9,413.9,1.9z M400.3,69.3h-6.5l-1.3-9.2h-7.8V30.3h6.5l1.3,9.2h7.8V69.3z"
          />
        </g> */
}
